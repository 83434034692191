import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

const QrIntro = (props) => {
    const data = props.data
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image" style={{minHeight: "232px"}}>
                            <div >
                                <picture>
                                    <source type="image/webp" srcset={ data.image.imageFile.childImageSharp.fluid.srcSetWebp }  importance="high" />
                                    <source type="image/jpg" srcset={ data.image.imageFile.childImageSharp.fluid.srcSet}/>
                                    <img
                                        alt={data.image.altText}
                                        importance="high"
                                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        importance="high"
                                        className="bg-position"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>{data.heading2}</h2>
                                <p style={{paddingTop: "1rem"}}>{data.subtitle}</p>

                                {/* <Link to={`/${data.ctaUrl}/`} className="default-btn">
                                    <i className="flaticon-right"></i>{data.cta}<span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default QrIntro;