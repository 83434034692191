import React, { useState, useEffect } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import QrIntro from '../components/Qrcode/QrIntro'

const ClientSideOnlyLazy = React.lazy(() =>
    import("../components/Qrcode/qrcodeForm")
)

const QrCodeTemplate = (props) => {
    let data = props.data.wpgraphql;

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : "",
            noIndex: "noindex"
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.page.qrPageAcf.intro.title}
                homePageText="Αρχική"
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                activePageText={data.page.qrPageAcf.intro.title}
            />
            <QrIntro
                data={data.page.qrPageAcf.intro}
            />
            {isOpen && (
                <React.Suspense fallback={<div />}>
                    <ClientSideOnlyLazy />
                </React.Suspense>
            )}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default QrCodeTemplate

export const pageQuery = graphql`
    query GET_QR_CODE($id: ID! , $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                qrPageAcf{
                    intro{
                        title
                        heading2
                        subtitle
                        cta
                        image {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 930, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                    }  
                }
            }

            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`